











































































































































import Vue from 'vue';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { Application, ApplicationService } from '../../libs/application';
import {
  OAuth2PKCEService,
  OAuth2Service,
  OAuth2AuthorizationRequest,
} from '../../libs/oauth2';
import { LocalDatabaseStrategy } from '../../libs/strategies';
import { routeToErrorPage } from '../../libs/util';
import {
  GoogleOAuth2Strategy,
  WindowsLiveStrategy,
  GitHubStrategy,
} from '../../libs/strategies';
import {
  GoogleOAuth2SocialButton,
  WindowsLiveSocialButton,
  GitHubSocialButton,
} from '../../components/social-buttons';

export default Vue.extend({
  mixins: [validationMixin],
  data() {
    return {
      isMounted: false,
      isFormLoading: false,

      request: OAuth2Service.getAuthorizationRequestFromRoute(this.$route),
      application: null as Application | null,

      form: {
        email: '',
        password: '',
      },
    };
  },
  components: {
    GoogleOAuth2SocialButton,
    WindowsLiveSocialButton,
    GitHubSocialButton,
  },
  validations: {
    form: {
      email: {
        required,
      },
      password: {
        required,
      },
    },
  },
  computed: {
    isLocalDatabaseSignupEnabled(): boolean {
      if (this.isStrategyActive('local-database') === false) {
        return false;
      }

      return this.getStrategy('local-database')?.signup === true;
    },

    showOrRow(): boolean {
      if (!this.application || !this.application.connections) {
        return false;
      }

      if (this.isStrategyActive('local-database') === false) {
        return false;
      }

      return this.application.connections.some(
        (connection) => connection.strategy !== 'local-database',
      );
    },
  },

  async mounted() {
    if (!this.request.client_id) {
      routeToErrorPage();
      return;
    }

    try {
      this.$set(
        this,
        'application',
        await ApplicationService.getByClientID(this.request.client_id),
      );
    } catch (e) {
      routeToErrorPage();
      return;
    }

    // Redirect to strategy, if defined
    if (this.$route.query.strategy?.toString()) {
      this.onStrategy(this.$route.query.strategy?.toString());
    }

    this.$set(this, 'isMounted', true);
  },

  methods: {
    getStrategy(
      strategy: Application['connections'][number]['strategy'],
    ): Application['connections'][number] | undefined {
      return (this.application as Application).connections.find(
        (connection) => connection.strategy === strategy,
      );
    },

    isStrategyActive(
      strategy: Application['connections'][number]['strategy'],
    ): boolean {
      if (!this.application) {
        return false;
      }

      return this.getStrategy(strategy) !== undefined;
    },

    /**
     * begin::local-database
     */
    async onSubmit() {
      if (this.isStrategyActive('local-database') === false) {
        return;
      }

      const strategy = this.getStrategy('local-database');

      this.$set(this, 'isFormLoading', true);
      try {
        await LocalDatabaseStrategy.authorize(
          this.request.client_id,
          strategy?.identifier as string,
          this.form.email,
          this.form.password,
        );

        // Perform authorization request
        const authorization = await OAuth2Service.authorize(
          this.request as OAuth2AuthorizationRequest,
        );

        // Redirect back to redirect URI
        OAuth2Service.redirectToURI(authorization, this.request?.redirect_uri);
        return;
      } catch (e) {
        console.error(e);
        this.$set(this, 'isFormLoading', false);
        return;
      }
    },

    onSignup() {
      const strategy = this.getStrategy('local-database');
      if (!strategy) {
        return;
      }

      if (strategy.signup !== true) {
        return;
      }

      return this.$router.push({
        path: '/signup',
        query: {
          ...this.$route.query,
          connection: strategy.identifier,
        },
      });
    },

    onForgotPassword() {
      const strategy = this.getStrategy('local-database');
      if (!strategy) {
        return;
      }

      return this.$router.push({
        path: '/forgot-password',
        query: {
          ...this.$route.query,
          connection: strategy.identifier,
        },
      });
    },
    /**
     * end::local-database
     */

    onStrategy(strategy: string): void {
      if (this.isStrategyActive(strategy) === false) {
        throw new Error(`Strategy '${strategy}' unknown`);
      }

      const connection = this.getStrategy(strategy);
      if (!connection) {
        return;
      }

      // Save request in session
      OAuth2Service.setStrategySession(
        this.request.client_id,
        connection.strategy,
        this.request,
      );

      // Create & save PKCE challenge for callback
      const pkce = OAuth2PKCEService.createPKCEChallenge();
      OAuth2PKCEService.savePKCEChallengeForClientID(
        this.request.client_id,
        connection.strategy,
        pkce,
      );

      // Define state
      const state = OAuth2Service.createStrategyState({
        type: 'authorize',
        application_client_id: this.request.client_id,
        client_id: connection.client_id as string,
        strategy: connection.strategy,
      });

      switch (connection.strategy) {
        case 'google-oauth2': {
          const uri = GoogleOAuth2Strategy.getAuthorizeURL(
            connection.client_id as string,
            {
              state,
              scope: connection?.scope?.join(' '),
              prompt: 'select_account',

              code_challenge: pkce.code_challenge,
              code_challenge_method: 'S256',
            },
          );

          window.location.href = uri.href;
          break;
        }

        case 'windowslive': {
          const uri = WindowsLiveStrategy.getAuthorizeURL(
            connection.client_id as string,
            connection.tenant as string,
            {
              state,
              scope: connection?.scope?.join(' '),
              prompt: 'select_account',

              code_challenge: pkce.code_challenge,
              code_challenge_method: 'S256',
            },
          );

          window.location.href = uri.href;
          break;
        }

        case 'github': {
          const uri = GitHubStrategy.getAuthorizeURL(
            connection.client_id as string,
            {
              state,
              scope: connection?.scope?.join(' '),

              code_challenge: pkce.code_challenge,
              code_challenge_method: 'S256',
            },
          );

          window.location.href = uri.href;
          break;
        }
      }
    },
  },
});
